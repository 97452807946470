<template>
  <div class="me" :class="editable || isVector ? 'editable' : ''" v-if="entry">
    <template v-if="!editing">
      <span
        :class="editable || isVector ? 'editable' : ''"
        @click.stop.prevent="onEditButtonClicked"
      >
        <span :class="{previous: hasPendingCommands}"
          >{{ formatedDataValue }}
        </span>
        <span v-if="hasPendingCommands" class="command-status">
          <span class="forecast"> ({{ lastCommandValue }}) </span>
          <PendingCommandIndicator
            :entry="entry"
            :editor="editable"
            @click.stop.prevent="onEditButtonClicked"
            @commandRemoved="$emit('commandRemoved', $event)"
            class="command-status-icon"
          />
        </span>
        <div v-else class="btn-status">
          <i v-if="busy" class="fa fa-refresh fa-spin text-warning"></i>
          <i v-if="!busy && editable" class="fa fa-pencil editable"></i>
          <i
            v-if="!busy && !editable && isVector"
            class="fa fa-eye editable"
          ></i>
          <i
            v-if="!busy && !editable && !isVector"
            class="fa fa-pencil invisible"
          ></i>
        </div>
      </span>
    </template>
    <template v-else>
      <DataValueInput
        :entry="entry"
        :control="editControl"
        :saveBtn="true"
        undoBtn="left"
        @undo="editing = false"
        @save="onSave"
        @change="$emit('change', $event)"
        class="inline-editor"
      />
    </template>
  </div>
</template>

<script>
import PendingCommandIndicator from "@/components/data/pending-command-indicator.vue";
import DataValueInput from "@/components/data-value-input.vue";
import {currentValueTypeCast} from "@/services/equipment-data.js";
export default {
  name: "DataValueDisplay",
  props: {
    entry: {
      type: Object,
      required: false,
      default: () => null
    },
    editor: {
      type: Boolean,
      default: false,
      required: false
    },
    showUnitLabel: {
      type: Boolean,
      required: false,
      default: false // !Important, since there are tables with its own unit label column
    }
  },
  components: {
    PendingCommandIndicator,
    DataValueInput
  },
  data() {
    return {
      editing: false,
      busy: false
    };
  },
  computed: {
    mode() {
      return this.$store.getters["dashboard/mode"];
    },
    readOnly() {
      return this.entry ? this.entry.read_only : true;
    },
    isDisabled() {
      return (
        !this.entry ||
        !this.entry.enabled ||
        !this.entry?.device?.enabled ||
        !this.entry?.device?.connector?.enabled
      );
    },
    editable() {
      return (
        this.mode != "editor" &&
        this.editor &&
        this.$can("manage", "DadoEscrita") &&
        !this.readOnly &&
        !this.isDisabled
      );
    },
    editControl() {
      return {
        equipment_id: this.entry.clp_id,
        data_id: this.entry.id,
        value: this?.entry?.current_value ? this.rawValue : "",
        unit: this?.entry?.unity_label || "",
        type: this?.entry?.type,
        dataType: this?.entry?.memory_type?.name,
        readOnly: this?.entry?.read_only,
        name: this.entry.name,
        format: this.$root.$formatter.dataFormat(this.entry) || "",
        decimal: "",
        required: null,
        default: false,
        data: this.entry,
        maxSize: this.$root.$formatter.maxInputLength(this.entry)
      };
    },
    title() {
      return (this.entry || {})?.current_value?.value || "";
    },
    lastCommandValue() {
      if (!this.hasPendingCommands || !this.entry) return "";
      let cmd = this?.entry?.pending_commands?.length
        ? this.entry.pending_commands[0]
        : this.entry.pending_mapping_write ?? null;
      if (!cmd) return "";
      let entry = JSON.parse(JSON.stringify(this.entry));
      entry.current_value = {value: cmd.writing_value};
      return this._formatedDataValue(entry, this.dataValueCurrentIndex);
    },
    hasPendingCommands() {
      return (
        (this?.entry.pending_commands || []).length > 0 ||
        this?.entry?.pending_mapping_write ||
        false
      );
    },
    memorySize() {
      return this.$root.$formatter.memorySize(this.entry);
    },
    dataValueIndex() {
      return this.$root.$formatter.dataValueIndex(this.entry);
    },
    dataValueCurrentIndex() {
      return this.$root.$formatter.dataValueCurrentIndex(this.entry);
    },
    rawValue() {
      return this._rawValue(this.entry, this.dataValueCurrentIndex);
    },
    formatedDataValue() {
      return this._formatedDataValue(this.entry, this.dataValueCurrentIndex);
    },
    isVector() {
      return this.memorySize > 1;
    }
  },
  methods: {
    onEditButtonClicked($event) {
      if (!this.editable && !this.isVector) return;
      if (
        this.entry.memory_size > 1 &&
        ($event.ctrlKey ||
          !this.dataValueIndex ||
          (this.dataValueIndex?.type == "constant" &&
            this.dataValueIndex?.value == -1))
      ) {
        this.$emit("editDataArray", this.entry);
        return;
      }
      this.editing = true;
    },
    onSave(value) {
      this.busy = true;
      let formData = [this.editControl];
      formData[0].value = value;
      this.$emit("save", formData);
      this.editing = false;
      setTimeout(() => {
        this.busy = false;
      }, 2000);
    },
    _rawValue(data, ix) {
      return ix < 0
        ? this.$root.$formatter.rawValue(data)
        : this.$root.$formatter.rawValue(data, ix);
    },
    _formatedDataValue(data, ix) {
      let vlr = "";
      let entry = {
        ...data,
        current_value: {
          ...(data.current_value || {})
        }
      };
      if (data.memory_size > 1 && (ix < 0 || ix > data.memory_size - 1)) {
        if (data?.current_value?.value !== undefined) {
          if (this.$root.$formatter.hasDataFormat(data)) {
            let lst = [];
            for (var i = 0; i < data.memory_size; i++) {
              entry.current_value.value = this._rawValue(data, i);
              currentValueTypeCast(entry);
              lst.push(this.$root.$formatter.format(entry));
            }
            vlr = `[${lst.join(", ")}]`;
          } else {
            vlr = entry?.current_value?.value ?? "";
          }
        }
      } else {
        entry.current_value.value = this._rawValue(data, ix);
        currentValueTypeCast(entry);
        vlr = this.$root.$formatter.format(entry);
      }
      return vlr === "" || vlr == null
        ? "-"
        : entry.unity_label !== "" &&
          this.showUnitLabel &&
          this.$root.$formatter.dataFormat(entry)?.format_mask !== "duration"
        ? `${vlr} ${entry.unity_label}`
        : vlr;
    }
  }
};
</script>

<style scoped>
.me {
  white-space: nowrap;
  display: inline-block;
  position: relative;
  width: 100%;
  padding-right: 1px;
  margin-left: -1px;
}

.editable:hover {
  cursor: pointer;
  /* opacity: 0.8; */
}

.command-status {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 50%;
  width: 50%;
  /* background: white; */
  /* forced-color-adjust: unset; */
  text-overflow: ellipsis;
  width: 50%;
  /* overflow: hidden; */
}

.command-status > .forecast {
  color: #7a643b;
  display: inline-block;
  max-width: 100%;
  width: calc(100% - 16px);
  margin-right: 2px;
  padding-left: 10px;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  vertical-align: bottom;
}

.skin-dark .command-status > .forecast {
  color: #728188;
}

.command-status > .command-status-icon {
  clear: both;
  font-size: 85%;
  display: inline-block;
  margin-right: 2px;
  min-width: 10px;
  vertical-align: text-top;
  z-index: 3;
}

.previous {
  display: inline-block;
  max-width: 50%;
  text-align: left;
  float: left;
  text-overflow: ellipsis;
  width: 50%;
  overflow: hidden;
}

.inline-editor {
  min-width: 100px;
  /* max-width: 230px; */
  max-height: 25px;
  font-size: 80%;
  padding: 0;
  margin: 0;
}

i.invisible {
  color: transparent;
}

i.disabled {
  color: #b4b4b4;
}

i.disabled:hover {
  cursor: not-allowed;
}

.editable {
  width: calc(100% - 12px);
  display: inline-block;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 13px;
  vertical-align: top;
  margin-top: 2px;
  margin-right: 0px;
}

.editable:hover {
  color: #337ab7;
  /*opacity: 0.8;*/
}
.editable:hover > .command-status > .command-status-icon {
  opacity: 1;
}

.editable:has(.command-status:hover) {
  overflow: visible;
}

.btn-status {
  position: relative;
  display: inline-block;
  width: 10;
  font-size: 90%;
}

.editable > .btn-status {
  position: absolute;
  width: 18px;
  right: -2px;
  z-index: 2;
}
</style>
